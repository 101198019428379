$bp: s;

.table {
  width: 100%;
}

.table__thead {
}

.table__thead__th {
  @extend %fw-normal;

  padding-bottom: 1rem;
  color: $c-gray-darker;
  text-align: left;
  border-bottom: 2px solid $elantis-grey-light;
}

.table__tbody {
  @include mq($until: $bp) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
  }
}

.table__tbody__tr {
  @include mq($until: $bp) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.table__tbody__td {
  width: 50%;
  padding: 1rem 0;
  line-height: 1;
  font-size: 1.8rem;

  &.table-label {
    font-size: 1.4rem;
  }

  .table__tbody__tr:first-child & {
    padding: 2rem 0 1rem;
  }

  @include mq($until: $bp) {
    .table__tbody__tr:first-child & {
      padding: 0;
    }

    padding: 0;
  }
}
