$menuBP: m;

.chrome {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 0 0 col(1);

  @include mq(m) {
    height: 100px;
    border-bottom: 1px solid $elantis-grey-light;
  }

  @include mq(xxl) {
    padding: 0 0 0 4.5rem;
  }
}

.chrome__logo {
  // prettier-ignore
  @include fluid(height, (xxs: 20px, xxl: 30px));
}

button.chrome__logout {
  @extend %fw-medium;

  display: flex;
  align-items: center;
  padding: 0;
  color: $elantis-blue;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 0.2rem;
  outline-offset: 0.5rem;
  outline: 2px transparent solid;

  &:focus-visible {
    outline-color: $denim;
  }
}

.chrome__logout__label {
  margin-top: 0.2rem;
  margin-right: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
}

.chrome__links {
  display: flex;
}

.chrome__links__help {
  @extend %focusable;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  margin: 0 1rem;
  color: $elantis-blue;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05rem;

  img {
    width: 4rem;
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-bottom: 0.5rem;
  }

  &:hover {
    text-decoration: underline;
  }

  @include mq($menuBP) {
    margin: 0 3rem;

    img {
      width: 6rem;
    }
  }

  @include mq($until: s) {
    display: none;
  }
}

.chrome__users {
  position: relative;
}

.chrome__users__menu {
  position: absolute;
  z-index: 10;
  top: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: $c-white;
  border: solid $elantis-grey-light;
  border-width: 0 1px 1px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s;

  li {
    padding: 0 2rem;
    list-style-type: none;

    &:not(:last-child) {
      border-bottom: 1px solid $elantis-grey-light;
    }

    &:hover {
      background: $c-gray-lightest;
    }
  }

  .is-open & {
    box-shadow: 0 10px 20px rgba($elantis-turquoise, 0.1);
    opacity: 1;
    pointer-events: auto;
  }
}

.chrome__users__item {
  @extend %fw-medium;

  display: flex;
  align-items: center;
  width: 100%;
  height: 5rem;
  padding: 0;
  color: $elantis-blue;
  line-height: 1;
  text-align: left;
  white-space: nowrap;
  background: transparent;
  border: 0;

  &:focus-visible {
    color: $c-white;
    background-color: $denim;

    svg {
      fill: $c-white;
    }
  }

  &.current {
    padding: 0 2rem;

    .icon {
      width: 1.5rem;
      margin: 0 0 0.5rem 1rem;
      transition: transform 0.15s;
    }

    .is-open & {
      .icon {
        transform: scale(-1);
      }
    }
  }

  &:hover {
    cursor: pointer;

    &.current {
      background: $c-gray-lightest;
    }
  }

  @include mq($menuBP) {
    height: 10rem;

    &.current {
      border: solid $elantis-grey-light;
      border-width: 0 1px;

      .icon {
        width: 2.4rem;
        margin-left: 2rem;
      }
    }
  }
}

.chrome__users__item__badge {
  @extend %fw-medium;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  color: $c-white;
  font-size: 1.3rem;
  line-height: 1;
  background: $elantis-orange;
  border-radius: 50%;

  .current & {
    background: $c-turquoise-dark;
  }

  @include mq($menuBP) {
    width: 5rem;
    height: 5rem;
    margin-right: 2rem;
    font-size: 2.3rem;

    span {
      margin-top: 4px;
    }
  }
}

.chrome__users__item__headline {
  display: block;
  margin-bottom: 0.2rem;
  font-size: 0.8rem;
  text-transform: uppercase;

  @include mq($menuBP) {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
}

.chrome__users__item__title {
  overflow: hidden;
  font-size: 1.3rem;
  text-overflow: ellipsis;
  text-transform: uppercase;

  @include mq($menuBP) {
    font-size: 1.5rem;
  }
}

.chrome__users__logout {
  @extend %fw-medium;

  justify-content: center;
  height: 3.5rem;
  color: $elantis-blue;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @include mq($menuBP) {
    height: 6.5rem;
  }
}

.chrome__users__logout__label {
  margin-top: 0.2rem;
  margin-right: 0.5rem;
}
