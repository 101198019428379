.e-label,
[class*='e-label--'] {
  @extend %fw-medium;

  display: inline-block;
  padding: 0.5rem 1rem 0.4rem;
  color: $elf-green;
  font-size: 0.9rem;
  line-height: 1;
  text-transform: uppercase;
  background: $c-white;
  border: 1px solid $elf-green;
  border-radius: 28px;

  &[class*='--orange'] {
    color: $elantis-orange;
    border: 1px solid $elantis-orange;
  }
}
