// Import fonts
@font-face {
  font-family: 'geomanist';
  src: url('./../assets/fonts/geomanist/regular/regular.woff2') format('woff2'),
    url('./../assets/fonts/geomanist/regular/regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'geomanist';
  src: url('./../assets/fonts/geomanist/regular-italic/regular-italic.woff2') format('woff2'),
    url('./../assets/fonts/geomanist/regular-italic/regular-italic.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'geomanist';
  src: url('./../assets/fonts/geomanist/medium/medium.woff2') format('woff2'),
    url('./../assets/fonts/geomanist/medium/medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'geomanist';
  src: url('./../assets/fonts/geomanist/book/book.woff2') format('woff2'),
    url('./../assets/fonts/geomanist/book/book.woff') format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'geomanist';
  src: url('./../assets/fonts/geomanist/bold/bold.woff2') format('woff2'),
    url('./../assets/fonts/geomanist/bold/bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}
