.wrapper,
[class*='wrapper--'] {
  width: 100%;
  max-width: $content-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: col(1);
  padding-left: col(1);

  &[class*='--no-padding'] {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(m) {
    padding-right: col(2);
    padding-left: col(2);
  }

  @include mq(xxl) {
    padding-right: calc(#{$content-width} / 16 * 3);
    padding-left: calc(#{$content-width} / 16 * 3);
  }
}
