.home {
  overflow: hidden;
}

.home__sections {
  @include mq(m) {
    min-height: calc(100vh - #{$header-height-large} - 1px);
  }
}

.home__login,
.home__intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.home__intro {
  position: relative;
  padding-right: col(1);
  padding-left: col(1);
  color: $elantis-blue;
  background: $c-turquoise-lighter;

  &::before {
    @include get-all-space;

    content: '';
    right: 100%;
    left: auto;
    background: $c-turquoise-lighter;
  }
}

.home__intro__error {
  display: flex;
  align-items: center;
  color: $c-black;

  svg {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.5rem;
    fill: $elantis-orange;
  }
}

.home__intro__features {
  display: flex;
  flex-direction: column;
  gap: 1;
  margin: 0;
  padding: 0;
}

.home__intro__features__item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  list-style-type: none;

  svg {
    height: 80%;
  }
}

.home__intro__excerpt {
  width: 100%;
  max-width: 37.5rem;
}

.home__intro__picture {
  // prettier-ignore
  @include fluid(width, (xxs: 120px, xxl: 500px));
}

.home__login {
  position: relative;
  padding-right: col(1);
  padding-left: col(1);

  &.is-loading {
    &::before {
      @include get-all-space;

      content: '';
      z-index: 1;
      background: rgba($c-white, 0.9) url('../assets/images/loader.svg') no-repeat center / 80px;
    }
  }
}

.home__login__btn {
  width: 100%;
  max-width: 26.5rem;
}

.home__login__ied {
  margin-bottom: 2.5rem;

  .is-mobile & {
    display: none;
  }
}

.home__login__eid-mobile {
  .is-desktop & {
    display: none;
  }
}

.home__login__error__description {
  text-align: left;

  ::v-deep a {
    color: $elantis-blue;
  }
}
