
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































































































.email,
[class*='email--'] {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-width: 80rem;
  background-color: $c-white;

  a {
    color: $elantis-blue;
  }
}

.email-small {
  color: var(--Elantis-GreyDark, #333);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.email__content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.email__links {
  display: flex;
  gap: 2rem;
}
