
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































.popup,
[class*='popup--'] {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.popup-outer {
  // prettier-ignore
  @include fluid(padding,(xxs: 20px, xxl: 40px));

  height: auto;
  background-color: $c-white;
}

.popup-inner {
  // prettier-ignore
  @include fluid(padding,(xxs: 20px, xxl: 40px));

  @include scrollbars;

  overflow: auto;
  height: 100%;
  max-height: 90dvh;
}

.popup__bg {
  @include get-all-space;

  z-index: -1;
  background-color: rgba($c-black, 0.7);
}
