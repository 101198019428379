html {
  font-size: calc(1em * 0.625);
}

body {
  /* sass-lint:disable-block no-vendor-prefixes */
  color: $c-dark;
  font: 1.4rem / 1.6 'geomanist', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @include mq(m) {
    font: 1.6rem / 1.8 'geomanist', sans-serif;
  }
}

a {
  color: $c-gray-darker;

  &:hover {
    text-decoration: none;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  @extend %fw-normal;

  color: $elantis-blue;
}

h1,
.h1 {
  @extend %fw-bold;
  // prettier-ignore
  @include fluid(font-size, (xxs: 25px, xxl: 50px));

  line-height: 1em;
}

h2,
.h2 {
  @extend %fw-book;
  // prettier-ignore
  @include fluid(font-size, (xxs: 18px, xxl: 36px));

  line-height: 1.3em;
}

h3,
.h3 {
  @extend %fw-book;
  // prettier-ignore
  @include fluid(font-size, (xxs: 16px, xxl: 25px));

  line-height: 1.5em;
}

h4,
.h4 {
  font-size: 1.8rem;
  line-height: 1.6em;
}

.p {
  // prettier-ignore
  @include fluid(font-size,(xxs: 12px, xxl: 18px));
  // prettier-ignore
  @include fluid(line-height,(xxs: 18px, xxl: 25px));
}

.p-small {
  // prettier-ignore
  @include fluid(font-size,(xxs: 10px, xxl: 4px));
  // prettier-ignore
  @include fluid(line-height,(xxs: 14px, xxl: 20px));
}

.lead {
  // prettier-ignore
  @include fluid(font-size, (xxs: 14px, xxl: 18px));
  // prettier-ignore
  @include fluid(line-height, (xxs: 18px, xxl: 25px));
}

.category {
  color: $elantis-blue;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 138.889%;
}

.column-title {
  @extend %fw-medium;
  // prettier-ignore
  @include fluid(font-size, (xxs: 12px, xxl: 20px));

  color: $c-gray-darker;
  line-height: 1;
}

.table-title,
[class*='table-title--'] {
  @extend .h2;

  padding-bottom: 2rem;
  color: $c-gray-darker;
  border-bottom: 2px solid $elantis-grey-light;

  &[class*='--no-border'] {
    padding-bottom: 0;
    border: 0;
  }
}

.table-label {
  @extend %fw-medium;

  // prettier-ignore
  @include fluid(font-size, (xxs: 12px, xxl: 14px));
}
