
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































































.help,
[class*='help--'] {
  ::v-deep {
    .popup-inner,
    .popup-outer {
      padding: 0;
    }
  }
}

.help-inner {
  position: relative;
  max-width: 95vw;

  @include mq(s) {
    display: flex;
  }
}

.help__close {
  @extend %focusable;

  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  padding: 0;
  background: $c-white;
  border: 1px solid $aquamarine;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.15s;

  &:hover {
    transform: scale(1.1);
  }
}

.help__button {
  position: relative;
  overflow: hidden;
  width: 7rem;
  height: 7rem;
  background: $c-white;
  border: 1px solid $elantis-turquoise;
  border-radius: 50%;
  box-shadow: 5px 5px 30px 0 rgba($elf-green, 0.15);

  &::before {
    @include get-all-space;

    content: '';
    display: block;
    background: url('../../assets/images/help.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    transition: transform 0.3s;
  }

  &:hover::before {
    transform: scale(1.5) rotate(90deg);
  }

  @include mq($until: l) {
    display: none;
  }
}

.help__button__label {
  @extend %fw-book;

  position: relative;
  color: $elantis-blue;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  transition: color 0.3s ease-out;

  .help__button:hover & {
    color: $elantis-orange;
  }
}

.help__problem {
  padding: 2.5rem 3rem;

  @include mq(m) {
    padding: 5.5rem 6rem;
  }
}

.help__more {
  padding: 2.5rem 3rem;

  img {
    display: block;
    height: 20rem;
    margin: 2rem auto;
  }

  @include mq(m) {
    padding: 4rem 5rem;
  }
}

.help__problem {
  background: $c-turquoise-lighter;
}

.help__title {
  @extend %fw-normal;

  color: $elantis-blue;
  font-size: 2.6rem;
  line-height: 1.2;

  @include mq(m) {
    font-size: 3.6rem;
  }

  strong {
    @extend %fw-bold;
  }
}

.help__text {
  color: $tundora;
  font-size: 1.6rem;
}

.help__options {
  margin-top: 4rem;

  @include mq(m) {
    display: flex;
    max-width: 600px;
  }
}

[class*='help__option--'] {
  @include mq($until: m) {
    max-width: 260px;

    & + & {
      margin-top: 4rem;
    }
  }

  @include mq(m) {
    width: calc((100% - 4rem) / 2);

    & + & {
      margin-left: 4rem;
    }
  }
}

.help__option__icon-outer {
  position: relative;
  width: 50px;
  height: 50px;
}

.help__option__icon {
  @include image-fit(contain, center left);
}

.help__option__title {
  @extend %fw-book;

  margin: 2rem 0;
  font-size: 2.4rem;
  line-height: 30px;
}

.help__option__text {
  @extend %fw-normal;

  color: $tundora;
  font-size: 1.4rem;
  line-height: 20px;
}

.help__option__call {
  @extend %fw-book;

  margin: 1rem 0;
  color: $elantis-blue;
  font-size: 2.4rem;
  text-decoration: none;
  transition: color 0.2s ease-out;

  &:hover {
    color: $elantis-orange;
  }
}

.help__option__callus {
  font-size: 1.1rem;
  font-weight: 500;
}

.help__option__btn[class*='--outline']:not(:hover) {
  background: $c-white;
}

.help__more__title {
  margin-bottom: 2rem;
  font-size: 36px;
  line-height: 1;

  ::v-deep b {
    display: block;
  }
}

.help__more__text {
  margin: 20px 0;
  font-size: 14px;
}

@include mq($until: m) {
  .help__more {
    margin-top: 20px;
  }
}
