.btn,
[class*='btn--'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 3.5rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background: $cg-yellow-blue;
  background-position: 100% center;
  background-size: 202%;
  border: 0;
  border-radius: 5px;
  transition: background 0.15s, color 0.15s, border 0.15s;

  &.disabled,
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.is-valid {
    background: $c-green;
  }

  &[class*='--link'] {
    padding: 0;
    background: transparent;
    border-radius: initial;
  }

  &[class*='--big'] {
    min-height: 4.5rem;
    padding: 1rem 2rem;
  }

  &[class*='--outline'] {
    background: $cg-yellow-transparent;
    background-position: 100% center;
    background-size: 200%;
    border: 1px solid $elantis-blue;
    transition: background-position 0.2s ease-out, border 0.2s ease-out;
  }

  &[class*='--reverse'] {
    flex-direction: row-reverse;
  }

  &:not(:disabled):not(.disabled):hover {
    cursor: pointer;
    background-position: 0% center;

    &[class*='--outline'] {
      background-position: 0% center;
      border: 1px solid transparent;
    }
  }
}

.btn__label {
  @extend %fw-book;

  display: inline-block;
  margin-top: 0.2rem;
  color: $c-white;
  font-size: 1.2rem;
  line-height: 1.5;
  transition: color 0.15s;

  [class*='btn--'][class*='--link'] &,
  [class*='btn--'][class*='--outline'] & {
    color: $elantis-blue;
  }

  [class*='btn--'][class*='--outline']:not(:disabled):not(.disabled):hover & {
    color: $c-white;
  }

  @include mq(m) {
    font-size: 1.4rem;

    [class*='btn--'][class*='--small'] & {
      font-size: 1.3rem;
    }
  }
}

.btn__icon {
  flex-shrink: 0;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 1rem;
  fill: $c-white;
  transition: fill 0.15s;

  [class*='btn--'][class*='--no-icon'] & {
    display: none;
  }

  [class*='btn--'][class*='--big'] & {
    width: 2.4rem;
    height: 2.4rem;
  }

  [class*='btn--'][class*='--reverse'] & {
    margin-right: 1rem;
    margin-left: 0;
  }

  [class*='btn--'][class*='--link'] &,
  [class*='btn--'][class*='--outline'] & {
    fill: $elantis-blue;
  }

  [class*='btn--'][class*='--outline']:not(:disabled):not(.disabled):hover & {
    fill: $c-white;
  }
}
