.g-link,
[class*='g-link--'] {
  position: relative;
  display: inline-block;
  color: $elantis-blue;
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: none;
  transition: color 0.2s ease-out;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 0.2rem;
    background: $cg-red-blue;
    background-position: 100% center;
    background-size: 200%;
    border-radius: 0.2rem;
    transition: background-position 0.4s ease-out;
  }

  svg {
    transition: fill 0.2s ease-out;
  }

  &:hover {
    color: $elantis-orange;

    svg {
      fill: $elantis-orange;
    }
  }

  &:hover::after {
    background-position: 0% center;
  }

  @include mq(m) {
    font-size: 1.6rem;
  }
}
