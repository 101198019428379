html {
  scroll-behavior: auto;

  &.js {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.wf-active,
    &.wf-inactive,
    &.ready {
      opacity: 1;
    }
  }
}

// SVG sprite
body > svg {
  display: none;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

svg {
  width: 2.4rem;
  height: 2.4rem;
  fill: $elantis-blue;
}

// NO SCROLL
.no-scroll {
  overflow-y: hidden;
  height: 100vh;
  padding-right: 15px;
}

// VARIOUS
hr {
  width: 100%;
  margin: 0;
  height: 2px;
  background-color: $elantis-grey-light;
  border: 0;
}

.is-hidden {
  visibility: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.page-enter-active,
.page-leave-active {
  transition: opacity 0.25s ease;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}

.h-visually-hidden {
  @extend %visually-hidden;
}
