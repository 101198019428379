
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























































.navigate-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.navigate-popup__actions {
  display: flex;
  gap: 1rem;
}
