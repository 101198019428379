
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















.feedback-message,
[class*='feedback-message--'] {
  display: flex;
  align-items: center;
  padding: 0.7rem 1rem;
  color: $c-green;
  font-size: 1.5rem;
  fill: $c-green;
  border-radius: 5px;

  &[class*='--filled'] {
    &.is-success {
      background: rgba($c-green, 0.3);
    }

    &.is-warning {
      background: rgba($frosted-mint, 0.1);
    }

    &.is-error {
      background: rgba($c-dev-warning, 0.3);
    }
  }

  &.is-success {
    color: $c-green;
    fill: $c-green;
    border: 1px solid $c-green;
  }

  &.is-warning {
    color: $yellow-orange;
    fill: $yellow-orange;
    border: 1px solid $yellow-orange;
  }

  &.is-error {
    color: $c-dev-warning;
    fill: $c-dev-warning;
    border: 1px solid $c-dev-warning;
  }
}

.label {
  margin-top: 0.3rem;
  line-height: 1.2;
}

.icon {
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.5rem;
  fill: inherit;
}
